@font-face {
  font-family: "HouschkaRoundedBold";
  font-style: normal;
  src: local("HouschkaRoundedBold") url('../src/fonts/houschkarounded-bold-webfont.ttf') format('truetype');
}
@font-face {
  font-family: "HouschkaRoundedDemiBold";
  src: local("HouschkaRoundedDemiBold") url('../src/fonts/houschkarounded-demibold-webfont.ttf') format('truetype');
}
@font-face {
  font-family: "HouschkaRounded";
  src: local("HouschkaRounded") url('../src/fonts/HouschkaRounded.ttf') format('truetype');
}
body {
  margin: 0;
  font-family: "HouschkaRoundedBold";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "HouschkaRoundedBold";
}
